html, body {
  margin : 0;
  background: #292f3b;
  color : #fff;
  font-family: Roboto, sans-serif;
  font-weight: normal;
  background-image: url('/v2/css/__publicv3/images/slide2.jpg');
  background-size: cover;
  height: 100%;
}

.container {
  width: 50%;
  margin: 0 auto;
  text-align: center;
  position: absolute;
  top : 0; bottom: 0;
  width: 100%;
  height: 400px;
  margin: auto;
}
h1 {
  font-weight: 200;
  font-size: 40px;
  margin: 0;
}
h2 {
  font-weight: 400;
  font-size: 40px;
  margin: 0;
  margin-top: 50px;
}
a {
  color : #ec602b;
}
p {
  font-weight: 100;
}